import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import useStyles from "./styles";
import QRCode from "react-qr-code";
import axios from "axios";
import { styled } from "@material-ui/core/styles";
import { cyan, grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import "./test.css";
import { useHistory } from "react-router";
import { styles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Image from "./background.png";
import Logo from "./single-tones.png";
import QrBack from "./QrBack.png";
const Qrcode = () => {
  const classes = useStyles();
  const history = useHistory();

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: cyan[300],

    "&:hover": {
      backgroundColor: cyan[200],
    },
  }));

  const styles = {
    paperContainer: {
      backgroundImage: `url(${Image})`,

      backgroundRepeat: "no-repeat",
    },
  };

  //const [istime,setTime] = useState(0);
  const [counter, setCounter] = useState(0);
  const [isScanned, setScanned] = useState("");
  const [adminId, setAdminId] = useState();
  var today = new Date(),
    time =
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds() +
      " "; //time

  //setTime(true);
  //console.log("time",istime);
  let newTime = time;

  //const token = localStorage.getItem("token");
  const token = "digitaez";
  /// newVal = time + token
  let newVal = newTime.concat(adminId);

  // console.log("concat result", newVal);

  const updateCount = () => {
    // Counter state is incremented
    setCounter(counter + 1);
  };

  const logout = () => {
    localStorage.clear();
    history.push("/");
  };

  const login = () => {
    let token = localStorage.getItem("token");
    //  console.log("Toekn", token);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    //*******   API to detect isSCAN value    *******/

    axios
      .get("http://insigniapp.digitaezonline.com/api/v1/auth/me", config)
      .then((res) => {
        //console.log("Res", res);
        // console.log("Res------", res.data.data.officeId);
        setAdminId(res.data.data.officeId);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    login();
    // console.log("Runing in useEffect (SCAN)");
    // console.log("Val of SCAN", isScanned);
  }, [isScanned]);

  useEffect(() => {
    //login();
    setTimeout(() => {
      // console.log("Runing in timeout");
      updateCount();
    }, 18000);
  }, [counter]);

  return (
    <>
      <div>
        <div className="background-img">
          <div className="d-flex justify-content-center  mt-2">
            <img
              src={Logo}
              className="img-fluid p-lg-3 "
              alt="Responsive image"
            ></img>
          </div>

          {/* large screen view */}
          <div class="main-div container-fluid 	d-none d-sm-block">
            <p className="text-white text-center">
              Scan your attendance. On Time and On Target !
            </p>

            <div class=" container mt-3 ">
              <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-12">
                  <div className="Qr-white-backLg">
                    <div class="row">
                      <div class="col text-center qr-setting">
                        <QRCode value={newVal} size={250} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {small screen view} */}
          <div class="main-div container-fluid 	d-block d-sm-none">
            <div class="d-flex justify-content-center text-white">
              <p className="p-6">
                Scan your attendance. On Time and On Target !
              </p>
            </div>
            <div class=" container ">
              <div class="row">
                <div class="col-md-6 offset-md-3">
                  <div className="Qr-white-back">
                    <div class="row">
                      <div class="col text-center mt-5">
                        <QRCode value={newVal} size={200} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Qrcode;
