import React from "react";
import SignIn from "./components/signIn";
import Qrcode from "./components/qrCode.js";
import Test from "./components/test.js";
import "./App.css";
//import { BrowserRouter ,Routes, Route,Switch } from 'react-router-dom';
//import { BrowserRouter, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute";
function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/test" component={Test} />
          <ProtectedRoute path="/attendance" component={Qrcode} />

          <Route path="/" component={SignIn} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;

//ali.muhammad@digitaez.com
//123456
