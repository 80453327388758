import React, { useState } from "react";
import { userSchema } from "./validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import "./signIn.css";
import Signlogo from "../logo-sign.svg";
import axios from "axios";
import { useHistory } from "react-router";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Sign_In() {
  const history = useHistory();
  const [isloading, setLoading] = useState(false);
  const [idOffice, setId] = useState();
  const signinError = () =>
    toast.info("Invalid Credentials", {
      position: "top-center",
      pauseOnHover: false,
    });

  const HandleForm = (data) => {
    const headers = {
      "Content-Type": "application/json",
    };

    setLoading(true);
    axios
      .post("http://insigniapp.digitaezonline.com/api/v1/auth/login", data, {
        headers: headers,
      })
      .then((res) => {
        //console.log("Response =>", res);

        //history.push('/attendance');
        setLoading(false);

        setId(res.data.user._id);

        localStorage.setItem("token", res.data.token);
        // navigate('/attendance')
        history.push("/attendance");
      })

      .catch((err) => {
        // console.log("hererere:::", err);
        signinError();
        setLoading(false);
      });
  };

  // axios.post('https://digitaezoffice.herokuapp.com/api/v1/auth/login',data,{headers:headers})

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  return (
    <>
      <div className="sign-in-background">
        <div className="sign-in-wrapper">
          <div>
            <img src={Signlogo} alt="" />
          </div>

          <form onSubmit={handleSubmit(HandleForm)}>
            <div className="sign-forms ">
              <div className="error-hndl d-flex justify-content-center">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className=" mt-2 form-rounded signform border-0"
                  placeholder="Enter Your Email"
                  autoComplete="off"
                  {...register("email")}
                />
              </div>
              <div className="error-hndl d-flex justify-content-center">
                <input
                  type="password"
                  id="password"
                  name="password"
                  className=" mt-2 form-rounded signform border-0"
                  placeholder="Enter Your Password"
                  autoComplete="off"
                  {...register("password")}
                />

                <p className="error-msg">{errors.email?.message}</p>
              </div>
            </div>
            {/* <div>
              
            </div> */}
            <div class="d-flex justify-content-center">
              <input
                type="submit"
                className="sign-inbtn shadow-sm mt-4"
              ></input>
            </div>
            {isloading ? (
              <div class="d-flex justify-content-center">
                <Loader
                  className="mt-5"
                  type="Oval"
                  color="#2cb9cf"
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              " "
            )}
            {/* <Loader className="loader" type="Oval" color="#2cb9cf" height={50} width={50}/>   */}
          </form>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default Sign_In;
