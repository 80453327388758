import { makeStyles } from "@material-ui/core/styles";
import QrBack from "./QrBack.png";
import Image from "./background.png";
export default makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  container: {
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    minWidth: "100%",
    height: "100vh",
  },
  subtitle: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
  qrcode: {
    backgroundImage: `url(${QrBack})`,
    height: "100%",
    width: "100%",
    backgroundRepeat: "no-repeat",
    //backgroundPosition: "center center",
    //backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
  },
  attendance: {
    padding: "30px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
}));
